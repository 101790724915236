<template>
  <transition name="el-fade-in-linear" :value="value" v-if="ifValue">
    <div class="wrapper">
      <div id="msgDialog" class="y3" :style="`width: ${width};`">
        <div v-if="type === 0" class="x3 aic h56 pw24">
          <p class="color3 fs16">{{ title }}</p>
          <i
            class="el-icon-close color9 fs24 pointer"
            @click="ifValue = false"
          ></i>
        </div>
        <div class="msgDialogBox">
          <div v-if="type === 1" class="x2">
            <i
              class="el-icon-close color9 fs24 pointer"
              @click="ifValue = false"
            ></i>
          </div>
          <slot></slot>
        </div>
        <div class="x2 aic h56 pw24" v-if="showFooter">
          <el-button
            size="small"
            style="font-size:13px;"
            v-if="NOText"
            @click="NOFunc"
          >
            {{ NOText }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            style="font-size:13px;"
            @click="OKFunc"
          >
            {{ OKText }}
          </el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    type: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    NOText: {
      type: String,
      default: "取消"
    },
    OKText: {
      type: String,
      default: "保存"
    },
    width: {
      type: String,
      default: "430px"
    }
  },
  data() {
    return {
      ifValue: false
    };
  },
  watch: {
    value(bool) {
      this.ifValue = bool;
    },
    ifValue(val) {
      /*使用了v-model的组件会自动监听 input 事件,
       * 并把这个input事件所携带的值 传递给v-model所绑定的属性,
       * 这样组件内部的值就给到了父组件了
       */
      this.$emit("input", val); //传值给父组件, 让父组件监听到这个变化
    }
  },
  methods: {
    OKFunc() {
      this.ifValue = false;
      this.$emit("OKFunc");
    },
    NOFunc() {
      this.ifValue = false;
      this.$emit("NOFunc");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
#msgDialog {
  background: $white;
  border: 1px solid $bdColor;
  border-radius: 5px;
  overflow: hidden;
  .msgDialogBox {
    border-top: 1px solid $bdColor;
    border-bottom: 1px solid $bdColor;
    min-height: 56px;
    padding: 24px;
  }
}
</style>
