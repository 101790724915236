var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ifValue ? _c('transition', {
    attrs: {
      "name": "el-fade-in-linear",
      "value": _vm.value
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "y3",
    style: "width: ".concat(_vm.width, ";"),
    attrs: {
      "id": "msgDialog"
    }
  }, [_vm.type === 0 ? _c('div', {
    staticClass: "x3 aic h56 pw24"
  }, [_c('p', {
    staticClass: "color3 fs16"
  }, [_vm._v(_vm._s(_vm.title))]), _c('i', {
    staticClass: "el-icon-close color9 fs24 pointer",
    on: {
      "click": function click($event) {
        _vm.ifValue = false;
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "msgDialogBox"
  }, [_vm.type === 1 ? _c('div', {
    staticClass: "x2"
  }, [_c('i', {
    staticClass: "el-icon-close color9 fs24 pointer",
    on: {
      "click": function click($event) {
        _vm.ifValue = false;
      }
    }
  })]) : _vm._e(), _vm._t("default")], 2), _vm.showFooter ? _c('div', {
    staticClass: "x2 aic h56 pw24"
  }, [_vm.NOText ? _c('el-button', {
    staticStyle: {
      "font-size": "13px"
    },
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.NOFunc
    }
  }, [_vm._v(" " + _vm._s(_vm.NOText) + " ")]) : _vm._e(), _c('el-button', {
    staticStyle: {
      "font-size": "13px"
    },
    attrs: {
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": _vm.OKFunc
    }
  }, [_vm._v(" " + _vm._s(_vm.OKText) + " ")])], 1) : _vm._e()])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }