<template>
  <div class="house">
    <div class="x3 aic mb16">
      <div>
        <el-input
          placeholder="楼盘名称"
          v-model="query.keyword"
          style="width: 320px;"
          class="f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
      <el-button type="primary" icon="el-icon-plus" @click="showForm = true">
        {{ title }}
      </el-button>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column label="楼盘信息">
          <div slot-scope="scope" class="x aic">
            <img :src="scope.row.imageUrl" class="avatar mr24" />
            <div class="y3" style="height:50px;">
              <div class="x aic">
                <p class="fs16 color3">{{ scope.row.name }}</p>
                <p
                  class="ml8"
                  v-if="scope.row.status !== '正常'"
                  :class="{
                    red: scope.row.status == '到期',
                    yellow: scope.row.status == '待审',
                    color9: scope.row.status == '驳回',
                    color3: scope.row.status == '正常'
                  }"
                >
                  {{ scope.row.status }}
                </p>
              </div>
              <p class="color9">
                {{ scope.row.regionName }}
                {{ scope.row.areaInterval && " | " + scope.row.areaInterval }} |
                {{
                  scope.row.avgPrice
                    ? `均价${scope.row.avgPrice}元/平米`
                    : "暂无均价"
                }}
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="相关操作" width="150">
          <div class="x" slot-scope="scope">
            <div @click="deleteById(scope.row.id)" class="flex1 x aic">
              <img src="../../../assets/qxgl.png" class="icon mr8" />
              <p class="pointer color6">取消关联</p>
            </div>
          </div>
        </el-table-column>
      </el-table>
      <f-pagination
        :page-num.sync="query.pageNum"
        :page-size.sync="query.pageSize"
        :total="total"
        @change="getList"
      ></f-pagination>
    </div>
    <msgDialog
      v-model="showForm"
      :title="title"
      OKText="加入"
      @OKFunc="OKFunc"
      width="650px"
    >
      <div class="mb24">
        <div class="mb24">
          <el-input
            placeholder="楼盘名称"
            v-model="query2.keyword"
            style="width: 320px;"
            class="f-search-bar"
          >
            <el-button slot="append" @click="linkEstateList(1)">搜索</el-button>
          </el-input>
        </div>
        <el-table
          class="light"
          :data="list2"
          ref="multipleTable"
          style="width: 100%"
          max-height="250"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="30"></el-table-column>
          <el-table-column label="楼盘名称">
            <div slot-scope="scope">
              <p>{{ scope.row.name }}</p>
            </div>
          </el-table-column>
          <el-table-column label="商圈">
            <div slot-scope="scope">
              <p v-if="scope.row.regionName">{{ scope.row.regionName }}</p>
              <p v-else>-</p>
            </div>
          </el-table-column>
          <el-table-column label="均价" width="180px">
            <div slot-scope="scope">
              <!-- <p>
                {{
                  scope.row.avgPrice
                    ? `${scope.row.avgPrice}元/平米`
                    : "暂无均价"
                }}
              </p> -->
              <p v-html="scope.row.priceInfoList.join('<br>')"></p>
            </div>
          </el-table-column>
          <el-table-column label="状态" width="80px">
            <div slot-scope="scope">
              <p
                :class="{
                  red: scope.row.status == '到期',
                  yellow: scope.row.status == '待审',
                  color9: scope.row.status == '驳回',
                  color3: scope.row.status == '正常'
                }"
              >
                {{ scope.row.status }}
              </p>
            </div>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="linkEstateList"
          :current-page.sync="query2.pageNum"
          :page-size="query2.pageSize"
          layout="sizes,prev,pager,next,jumper"
          :total="total2"
        >
        </el-pagination>
      </div>
    </msgDialog>
  </div>
</template>

<script>
import msgDialog from "@/components/msgDialog";

import {
  pagedLinkedHouse,
  deleteById,
  linkEstateList,
  recommendSwitch,
  linkHouse
} from "@/api/estateManage";

export default {
  components: {
    msgDialog
  },
  data() {
    return {
      title: "关联楼盘",
      query: {
        keyword: "",
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      total2: 0,
      list: [],
      pageSizes: [10, 20, 30, 50],
      showForm: false,
      query2: {
        keyword: "",
        pageNum: 1,
        pageSize: 10
      },
      list2: [],
      multipleSelection: []
    };
  },
  watch: {
    showForm(e) {
      if (e) {
        this.multipleSelection = [];
        this.query2.keyword = "";
        this.linkEstateList();
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange2(val) {
      this.query2.pageSize = val;
      this.linkEstateList();
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      this.getList();
    },
    async getList() {
      const res = await pagedLinkedHouse(this.query);
      const { total, list } = res;
      this.total = total;
      this.list = list || [];
    },
    search() {
      this.query.pageNum = 1;
      this.getList();
    },
    async deleteById(id) {
      const res = await deleteById(id);
      if (res) {
        this.search();
      }
    },
    async linkEstateList(num) {
      if (typeof num === "number") {
        this.query2.pageNum = num;
      }
      const res = await linkEstateList(this.query2);
      if (res) {
        const { total, list } = res;
        this.list2 = list;
        this.total2 = total;
      }
    },
    async recommendSwitch(id) {
      const res = await recommendSwitch(id);
      if (res) {
        this.search();
      }
    },
    async OKFunc() {
      if (!this.multipleSelection.length) {
        return;
      }
      let estateIdSet = this.multipleSelection.map(e => e.estateId);
      const res = await linkHouse({
        estateIdSet
      });
      if (res) {
        this.showForm = false;
        this.search();
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.house {
  background-color: $white;
  padding: 24px;
  .icon {
    width: 24px;
    height: 24px;
  }
  .avatar {
    width: 50px;
    min-width: 50px;
    height: 50px;
  }
}
</style>

<style lang="scss">
.house {
  .el-input-group__append {
    font-size: 13px;
    color: $color3;
  }
}
</style>
